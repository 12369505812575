#testimonial {
  width: 100%;
  position: relative;
  height: 100vh;

  .clients {
    padding: 0px;
    margin: 0px;
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 50%;
    transform: translatey(-50%);
    margin: 0 auto;
    .col-img {
      padding: 0px;
      height: calc(100vh - 147px);
      margin: 0px;
    }

    .img {
      position: relative;
      height: calc(100vh - 147px);
      background-image: url("../../public/Assets/Clients_icon.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .page-header {
    font-family: Orelega !important;
    color: #0184a3 !important;
    text-align: center;
    padding-top: 30px;
  }
  .cards {
    width: 165px;
    border: solid #0184a3 1px;
    border-radius: 10px;
    padding: 5px 20px;
    color: #0184a3 !important;
    margin: 20px auto;
    div {
      font-size: 22px;
      font-weight: 600;
      font-family: Orelega !important;
    }
    color: #0184a3 !important;
  }

  .carousel {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-color: black !important;
    }
    .carousel-indicators {
      bottom: -50px;
      button {
        background-color: black !important;
      }
    }

    .testimonial-card {
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      width: 100%;
      height: fit-content;
      .content {
        h3 {
          color: #4481c2;
          font-weight: 400;
          font-family: Orelega !important;
        }
        h5 {
          color: #4481c2;
          font-weight: 600;
        }
        p {
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #testimonial {
    height: 185vh;
    .clients {
      top: unset;
      transform: unset;
    }
  }
}
