.why-container {
  margin: 50px auto;
  text-align: start;
  margin-left: 20px;
  h2 {
    margin-top: 50px;
    font-family: Orelega !important;
    color: #0184a3;
  }

  .whysection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .parent {
    width: 300px;
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    text-align: center;
    overflow: hidden; /* Ensure that no content overflows */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow */

    .container {
      position: relative;
      img {
        width: 100%;
        height: auto;
        display: block;
        margin-top: 10px;
      }
      .overlaytext {
        position: absolute;
        bottom: 0; /* Aligns text to the bottom of the container */
        left: 24px;
        right: 0; /* Ensures full width */
        background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
        color: #ffffff; /* Text color */
        font-family: "Orelega One", sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
        padding: 10px; /* Add padding for better spacing */

        white-space: pre-wrap;

        border-radius: 50px;
        width: 250px;
      }
    }

    p {
      padding: 10px 20px;
      font-family: "Arial", sans-serif;
    }
  }
}
