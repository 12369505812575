#navbar {
  background-color: white;
  height: 80px;
  &.fixed-top {
    box-shadow: 0px -4px 7px 0px;
    background-color: white;
  }
  .navbar-nav {
    margin: 0 auto;
    padding-right: 20%;
  }
  .nav-link {
    margin: 0px 10px;
  }

  .contact-us {
    color: white;
    font-size: 16px;
    border-radius: 25px;
    background: linear-gradient(96.35deg, #38c6f4, #7cc57c);
    padding: 5px 10px;
    position: absolute;
    right: 25px;
  }
  .navbar-collapse {
    z-index: 999;
  }
}
@media (max-width: 768px) {
  #navbar {
    .navbar-nav {
      padding-right: 0px !important;
    }
    .navbar-collapse {
      background-color: white;
    }
    .contact-us {
      color: white;
      font-size: 16px;
      border-radius: 25px;
      background: linear-gradient(96.35deg, #38c6f4, #7cc57c);
      position: unset;
      right: unset;
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    margin: 0px !important;
  }
}
