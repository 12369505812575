#home {
  height: calc(100vh);
  background-image: url("../../public/Assets/Etripeasybanner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
  .home-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .discover-the-world-container {
      font-size: 85px;
      font-family: "Orelega" !important;

      .discover-the {
        background: linear-gradient(
          91.7deg,
          #0184a3,
          #78d1b4 19.5%,
          #beffbe 23.5%,
          #7cc57c 33%
        );

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .world-with-ease {
        color: white;
      }
    }
    .home-description {
      width: 100%;
      margin-left: 10px;
    }
    .btn-theme {
      color: white;
      font-size: 16px;
      border-radius: 25px;
      background: linear-gradient(96.35deg, #38c6f4, #7cc57c);
      padding: 5px 10px;
      min-width: 100px;
      width: fit-content;
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  #home {
    background-image: url("../../public/Assets/Etripeasybanner-small.png");
    .home-content {
      .discover-the-world-container {
        font-size: 40px;
      }
    }
  }
}
