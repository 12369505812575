#footer {
}
#footer-nav {
  position: absolute;
  bottom: 100px;
  background-color: #79c683;
  .navbar-nav .nav-link {
    margin: 0px !important;
    color: white;
    font-weight: 600;
  }
}
