@font-face {
  font-family: "NexaLight";
  src: url("../public/Fonts/Nexa/NexaDemo-Light.otf") format("opentype");
}
@font-face {
  font-family: "Orelega";
  src: url("../public/Fonts/Orelega/OrelegaOne-Regular.ttf");
}
body {
  margin: 0;
  font-family: "NexaLight", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "NexaLight", sans-serif !important;
}
