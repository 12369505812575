#pre-header {
  flex-direction: row-reverse;
  background-color: #0a85a3;
  height: fit-content;
  .row {
    height: fit-content;
  }
  .col {
    padding: 0px 3px;
  }
  a {
    text-align: center;
    font-size: 12px;
    color: white;
    text-decoration: blink;
    img {
      padding-right: 2px;
    }
  }
}
