#serives {
  background-image: url("../../public/Assets/services_bg.png");
  width: 100%;
  //   height: 100vh;
  .serives {
    padding: 0px;
    margin: 0px;
    .col-img {
      padding: 0px;
      height: calc(100vh - 147px);
      margin: 0px;
    }
    .col-con {
      padding: 0px;
      height: calc(100vh - 147px);
      margin: 0px;
    }

    .img {
      position: relative;
      height: calc(100vh - 147px);
      background-image: url("../../public/Assets/serivce_girl.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      img {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    .service-container {
      color: white;
      width: calc(100% - 50px);
      margin: 0 auto;
      .service-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
      }

      .service-item h2 {
        font-family: Orelega !important;
        font-weight: 600;
        color: #0eb8d2 !important;
        margin-right: 20px;
      }

      .service-item h3 {
        font-family: Orelega !important;
      }

      .service-item p {
        margin: 0;
        font-size: 1rem; /* Adjust to match the font size of the descriptions */
      }
    }
  }
  .page-header {
    padding: 50px 50px;
    font-family: Orelega !important;
    color: white;
  }
}

@media (max-width: 768px) {
  #serives {
    .col-img {
      display: none;
    }
  }
}
