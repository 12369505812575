#contact {
  height: 100vh;
  position: relative;
  .left-con {
    h2 {
      padding: 0px 20px;
      font-family: Orelega !important;
      color: #0184a3;
    }
    p {
      padding: 0px 20px;
      text-align: center;
    }
    img {
      height: 500px;
      position: absolute;
      bottom: 150px;
    }
  }
}
.form-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}
.btn-theme {
  color: white;
  font-size: 16px;
  border-radius: 25px;
  background: linear-gradient(96.35deg, #38c6f4, #7cc57c);
  padding: 5px 10px;
  min-width: 100px;
  width: fit-content;
  border: none;
  margin: 0 auto;
}
.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  margin: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #416ab2;
  border-radius: 5px;
  box-sizing: border-box;

  &::placeholder {
    font-size: 12px;
  }
  &:hover {
    border: 1px solid #0a99ff;
  }
}

form {
  margin-top: 150px;
  text-align: center;
  .toast {
    width: calc(100% - 45px);
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  #contact {
    .left-con {
      height: 100px;

      img {
        display: none;
      }
    }
    form {
      margin-top: 0px;
      margin-bottom: 40px;
    }
  }

  .foot {
    .company {
      .follow {
        display: none;
      }
    }
    .poweredby {
    }
  }
}

.foot {
  height: 100px;
  position: absolute;
  bottom: 0px;
  background-image: url("../../public/Assets/foot.png");
  background-color: #79c683;
  background-size: contain;
  .company {
    background-color: #499853;
    opacity: 0.7;
    height: fit-content;
    padding: 10px 0px;
    a {
      text-align: center;
      font-size: 15px;
      color: white;
      font-weight: 700;
      text-decoration: blink;
    }
  }
  .poweredby {
    height: fit-content;
    color: #174e1f;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0px;

    .copy {
    }
    .dev {
      text-align: right;
      padding-right: 10px;
    }
  }
}
