.aboutus {
  .Parent {
    width: 100%;
    height: calc(100vh - 300px);
    background-image: url("./../../../EtripEasy/public/Assets/Group.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    padding-left: 40px;
    .c-about {
      position: relative;
      height: calc(100vh - 300px);

      h3 {
        margin-top: 50px;
        font-family: Orelega !important;
        color: #0184a3;
      }
      .center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}

.imageright {
  padding: 10px 10px 100px 10px;
  position: relative;
  img {
    position: absolute;
    right: 40px;
  }
}
.description {
  padding: 60px 10px 10px 10px;
  img {
    margin-top: 50px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .aboutus {
    .imageright {
      display: none;
    }
  }
}
