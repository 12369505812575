#destinations {
  justify-content: center;
  .des-cards {
    height: fit-content;
    background: white;
    box-shadow: 0 1027px 288px transparent, 0 658px 263px rgba(0, 0, 0, 0.01),
      0 370px 222px rgba(0, 0, 0, 0.05), 0 164px 164px rgba(0, 0, 0, 0.09),
      0 41px 90px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 40px;
    .des-card {
      position: relative;
      height: 150px;

      .des {
        font-family: "Orelega" !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #0184a3;
        font-weight: 500;
        font-size: 25px;
      }
    }
    .des-img {
      font-family: "NexaLight" !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
    .divieder {
      height: 50px;
      position: absolute;
      width: 1px;
      background-color: #c4c4c4;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
